'use client'
import React from "react";

import { Swiper, SwiperSlide } from 'swiper/react';
import LargeSwiperImage from '@components/LargeSwiperImage'
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import Image from "next/image";
import LinkComponent from "./LinkComponent";

const DistrictCardSlider = ({ title, description, districts, dictionary, lang }) => {
    const districtArray = []
    let activeSmallGroup = null
    districts.forEach((dis, index) => {
        // console.log(index, activeSmallGroup, dis)
        if (dis.size == 'large' && activeSmallGroup == null) {
            districtArray.push(dis)
        }
        if (dis.size == 'large' && activeSmallGroup !== null) {
            districtArray.push(dis)
            activeSmallGroup = null
        }
        if (dis.size == 'small') {
            if (activeSmallGroup == null) {
                activeSmallGroup = districtArray.length
                districtArray.push({
                    size: 'small',
                    cards: [dis]
                })
            } else if (activeSmallGroup !== null) {
                const lastGroup = districtArray[activeSmallGroup]
                lastGroup?.cards.push(dis)
            }
        }
    })
    
    return (
        <section className='pl-3 sm:pr-0 pr-3 mt-4 mb-4 w-full'>
            {title !== '' && (
                <h2 className='text-maincolor font-bold text-xl'>{title[lang]}</h2>
            )}
            <div className='md:block hidden'>
                <Swiper
                    navigation={true}
                    modules={[Navigation]}
                    pagination={{
                        clickable: true,
                    }}
                    spaceBetween={16}
                    slidesPerView={4}
                    className="mt-2"
                >
                    {districtArray[0].cards.map((district, index) => {
                        if (district.size === 'small') {
                            return (
                                <SwiperSlide key={index}>
                                    <LargeSwiperImage location={district.value} type={district.type} title={district.label} imageSrc={district?.coverImage} />
                                </SwiperSlide>
                            )
                        }
                    })}
                </Swiper>
            </div>
            <div className="md:hidden flex flex-row flex-wrap">
                {districtArray[0].cards.map((district, index) => {
                    if (district.size === 'small') {
                        return (
                            <div key={index} className="w-1/3 p-1 aspect-square">
                                <LinkComponent href={`/properties?${district.type}=${district.value}`}>
                                    <div className="relative aspect-square w-full rounded-xl overflow-hidden shadow-md shadow-maincolor/30">
                                        <Image
                                            src={district.coverImage}
                                            fill={true}
                                            className='object-cover'
                                            alt={district.label}
                                        // style={{ objectFit: "contain" }}
                                        />
                                        <div className="absolute z-10 bottom-0 top-0 left-0 right-0 text-light flex items-center justify-center text-center bg-maincolor/40">
                                            {district.label}
                                        </div>
                                    </div>
                                </LinkComponent>
                            </div>

                                // {/* <LargeSwiperImage location={district.value} type={district.type} title={district.label} imageSrc={district?.coverImage} /> */}

                        )
                    }
                })}
            </div>
        </section>
    )
}

export default DistrictCardSlider